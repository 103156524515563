import { Components } from "formiojs";
import { Component } from "formiojs/types/components/_classes/component/component";
import Validator from "formiojs/validator/Validator";
import intlTelInput from "intl-tel-input/intlTelInputWithUtils";
import * as intlTelInputI18n from "intl-tel-input/i18n";
import { Iti, SomeOptions } from "intl-tel-input";

const Input = Components.components.input;
const errorMap = [
    "invalidPhoneNumber",
    "invalidCountryCode",
    "phoneTooShort",
    "phoneTooLong",
];

Validator.validators.phoneNumber = {
    key: "validate.phoneNumber",
    message(component: Component) {
        const d = (component as IntlPhoneNumberComponent).delegate;
        const errorCode = d?.getValidationError() ?? 0;
        return component.t(errorMap[errorCode] || "invalidPhoneNumber", {
            field: component.errorLabel,
            data: component.data,
        });
    },
    check(component: Component, _setting: any, _value: any) {
        const d = (component as IntlPhoneNumberComponent).delegate;
        const res = d?.isValidNumber();
        return res ?? false;
    }
}

export default class IntlPhoneNumberComponent extends Input {
    refs!: { [key: string]: HTMLElement[] };
    delegate: Iti | null = null;
    private onCountryChanged: EventListener;

    constructor(component: Component | any, options: Object, data: any) {
        super(component, options, data);

        this.validators.push("phoneNumber" as any);
        let self = this;
        this.onCountryChanged = function (event: Event) {
            if (!self.delegate) return;
            self.setValue(self.delegate.getNumber(), { changed: true });
        };
    }

    /**
     * Define the default schema to change the type and tag and label.
     */
    static schema() {
        return Input.schema({
            label: "Phone Number",
            type: "phoneNumber",
            inputType: "tel",
            inputMode: "tel",
            //* Whether or not to allow the dropdown.
            allowDropdown: true,
            //* Add a placeholder in the input with an example number for the selected country.
            autoPlaceholder: "polite",
            //* Modify the parentClass.
            containerClass: "",
            //* The order of the countries in the dropdown. Defaults to alphabetical.
            countryOrder: null,
            //* Append menu to specified element.
            dropdownContainer: null,
            //* Don't display these countries.
            excludeCountries: [],
            //* Fix the dropdown width to the input width (rather than being as wide as the longest country name).
            fixDropdownWidth: true,
            //* Format the number as the user types
            formatAsYouType: true,
            //* Format the input value during initialisation and on setNumber.
            formatOnDisplay: true,
            //* Inject a hidden input with the name returned from this function, and on submit, populate it with the result of getNumber.
            hiddenInput: null,
            //* Initial country.
            initialCountry: "",
            //* National vs international formatting for numbers e.g. placeholders and displaying existing numbers.
            nationalMode: true,
            //* Display only these countries.
            onlyCountries: [],
            //* Number type to use for placeholders.
            placeholderNumberType: "MOBILE",
            //* Show flags - for both the selected country, and in the country dropdown
            showFlags: true,
            //* Display the international dial code next to the selected flag.
            separateDialCode: false,
            //* Only allow certain chars e.g. a plus followed by numeric digits, and cap at max valid length.
            strictMode: false,
            //* The number type to enforce during validation.
            validationNumberType: "MOBILE",
        });
    }

    static get builderInfo() {
        return {
            title: "Phone Number",
            group: "basic",
            icon: "phone-square",
            weight: 35,
            schema: IntlPhoneNumberComponent.schema(),
        };
    }

    // @ts-expect-error
    override get defaultSchema() {
        return IntlPhoneNumberComponent.schema();
    }

    override async attach(element: HTMLElement) {
        const $input = (element as HTMLElement).querySelector("input") as HTMLInputElement;
        this.attachTo($input);
        return super.attach(element);
    }

    private attachTo($input: HTMLInputElement, installEventListener = true) {
        if (this.options.readOnly) return;
        const self = this;
        const options: SomeOptions = {
            i18n: intlTelInputI18n[this.options.language as keyof typeof intlTelInputI18n],
            customPlaceholder(selectedCountryPlaceholder, _selectedCountryData) {
                return self.component.placeholder || selectedCountryPlaceholder;
            },
            ...this.component,
        };
        this.delegate = intlTelInput($input, options);
        if (installEventListener) {
            $input.addEventListener("countrychange", this.onCountryChanged);
        }
    }

    override getValue() {
        return this.delegate?.getNumber();
    }

    override setValue(value: string, flags: { [key: string]: boolean | undefined }): boolean {
        // console.log(this.key, value, flags, this.refs.input);
        /**
         * When inside a panel this.refs.input is empty, and the logic in super
         * works fine for some reason; reattach can't be done anyway unless a
         * reference to $input is available.
         */
        if (value && flags.fromSubmission && this.refs.input.length > 0) {
            this.delegate?.destroy();
            const res = super.setValue(value, flags);
            this.attachTo(this.refs.input[0] as HTMLInputElement, false);
            return res;
        }
        return super.setValue(value, flags);
    }

    override detach() {
        if (this.delegate) {
            const $input = this.refs.input[0];
            $input.removeEventListener("countrychange", this.onCountryChanged);
            this.delegate.destroy();
            this.delegate = null;
        }
        super.detach();
    }

    override isValid(data: any, dirty: boolean): boolean {
        return this.delegate?.isValidNumber() ?? false;
    }
}
