import { Formio } from "formiojs";
import components from "./components";

interface Lang {
    [key: string]: string;
}

interface LangMap {
    [key: string]: Lang;
}

const LANGUAGES: LangMap = {
    ar: {},
    bg: {},
    bn: {},
    bs: {},
    ca: {},
    cs: {},
    da: {},
    de: {},
    el: {},
    en: {
        invalidCountryCode: "Invalid country calling code.",
        invalidPhoneNumber: "The string supplied did not seem to be a phone number.",
        phoneTooShort: "The string supplied is too short to be a phone number.",
        phoneTooLong: "The string supplied is too long to be a phone number.",
    },
    es: {
        Submit: "Enviar",
        "Please correct all errors before submitting.":
            "Por favor, corrija todos los errores antes de enviar.",
        required: "{{field}} es requerido.",
        invalid_email: "{{field}} debe ser un correo electrónico válido.",
        error: "Por favor, corrija los siguientes errores antes de enviar.",
        submitError: "Por favor, revise el formulario y corrija todos los errores antes de enviar.",
        invalidCountryCode: "Código de país inválido.",
        invalidPhoneNumber: "Debe indicar un número de {{field}} válido.",
        phoneTooShort: "El número es muy corto.",
        phoneTooLong: "El número es muy largo.",
        minSelectedCount: "Selecciona mínimo {{minCount}} opciones",
        maxSelectedCount: "Selecciona máximo {{maxCount}} opciones",
    },
    fa: {},
    fi: {},
    fr: {},
    hi: {},
    hr: {},
    hu: {},
    id: {},
    it: {},
    ja: {},
    ko: {},
    mr: {},
    nl: {},
    no: {},
    pl: {},
    pt: {},
    ro: {},
    ru: {},
    sk: {},
    sv: {},
    te: {},
    th: {},
    tr: {},
    uk: {},
    ur: {},
    vi: {},
    zh: {},
};


function mergeLanguage(target: Lang, source: Lang) {
    for (const [key, value] of Object.entries(source)) {
        target[key] = value;
    }
}

function mergeLanguages(source: LangMap) {
    for (const [key, value] of Object.entries(source)) {
        const lang = LANGUAGES[key];
        if (lang) {
            mergeLanguage(lang, value);
        } else {
            LANGUAGES[key] = value;
        }
    }
}

const FormioExtras = {
    components,
};

Formio.use(FormioExtras);
export default {
    mergeLanguages
}

declare global {
    namespace Formio {
        function mergeLanguages(source: LangMap): void;
        let languages: LangMap;
    }
}

globalThis.Formio.mergeLanguages = mergeLanguages;
globalThis.Formio.languages = LANGUAGES;
